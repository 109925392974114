import {
    BookOutlined,
    HomeOutlined,
    LaptopOutlined,
    CodeOutlined,
    CheckCircleOutlined
  } from '@ant-design/icons';
  
  const timelineData = [
    {
      startYear: '',
      endYear: '2007',
      event: 'Finished high school',
      description: 'I have completed high school education in B.E.H.S Taze, Sagaing Division.',
      skills: ['High School'],
      icon: <BookOutlined />
    },
    {
      startYear: '2007',
      endYear: '2007',
      event: 'After final exam, I started learning computer basics',
      description: 'I start with computer, i dicide to workin in computer field.',
      skills: ['First Computer '],
      icon: <HomeOutlined />
    },
    {
      startYear: '2008',
      endYear: '2011',
      event: 'Start - University of Computer Studies, Monywa',
      description: 'Studied at Computer University and graduated in 2011.',
      skills: ['Computer Science', 'Software Engineering'],
      icon: <LaptopOutlined />
    },
    {
      startYear: '2012',
      endYear: '2012',
      event: 'Very first earned job in Yangon',
      description: 'Worked as a Sale Person, Data Entry and work in a Store in Yangon.',
      skills: ['Sales', 'Communication'],
      icon: <HomeOutlined />
    },
    {
      startYear: '2013',
      endYear: '2013',
      event: 'ICTTI Certification',
      description: 'I enter the entrace exam, pass to attend ICTTI Software engineering.',
      skills: ['Software Engineering',"Java","MySQL","HTML","CSS","JavaScript"],
      icon: <CheckCircleOutlined />
    },
    {
      startYear: '2013',
      endYear: '2018',
      event: 'National IT Solution Co., Ltd',
      description: 'Worked at NITS as a Junior Programmer, Excutive ',
      skills: ['Programming', 'Team Collaboration','PHP','MySQL','CakePHP',"CodeIgniter","Laravel"],
      icon: <CodeOutlined />
    },
    {
      startYear: '2018',
      endYear: '2019',
      event: 'City Capital Development Co., Ltd',
      description: 'Worked at CCD Company as a Senior Web Developer, working for G2C, H2R',
      skills: ['Web Development', 'Project Management',"Laravel"],
      icon: <CodeOutlined />
    },
    {
      startYear: '2019',
      endYear: '2022',
      event: 'Career-Tech Japan Co., Ltd',
      description: 'Working as a Lead Full-Stack Developer at Career-Tech Japan Co., Ltd.',
      skills: ['Full-Stack Development', 'Leadership',"Laravel","CakePHP","VueJs","ReactJs","Flutter"],
      icon: <CodeOutlined />
    },
    {
      startYear: '2022',
      endYear: '2023',
      event: 'More & More ',
      description: 'More & more jwelry company to develop their own Web ERP',
      skills: ['Full-Stack Development', 'Leadership',"Laravel","ReactJs"],
      icon: <CodeOutlined />
    },
    {
      startYear: '2023',
      endYear: '2024',
      event: 'Develop-X',
      description: 'Working as a Backend Developer',
      skills: ['Laravel', ],
      icon: <CodeOutlined />
    },
    {
      startYear: '2024',
      endYear: '2024',
      event: 'FLCT',
      description: 'Working as a Flutter Developer',
      skills: ['Flutter'],
      icon: <CodeOutlined />
    }
  ];
  
  export default timelineData;
  